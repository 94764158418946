/* changes for the static template */
.content-template #primary-content .module {
  padding: 16px 24px 24px;
}


/* fixed width start ======================== */

.fixed-width aside
{
	width: 25%;
	min-width:320px;
}

.fixed-width #primary-content
{
	min-width: 960px;
}

.fixed-width #content-box,
.fixed-width #header
{
	min-width: 1286px;
}

.fixed-width #drawer
{
	display: none;
	visibility: hidden;
}

/* make NAv work: */
.fixed-width nav#primary-nav.wide ul.nav-secondary li .toggle-content
{
	display: block;
}

/* Fix news header items */

.fixed-width #news-header .news-item div {
    width: 65px;
    overflow: hidden;
    margin: 0 8px 0 0;
    float: left;
}

.fixed-width #news-header .news-item img {
    height: 56px;
    max-width: none;
    float: left;
    margin: 0px 8px 0px -8px;
}

/* fixed width end ========================= */

/* Hide news header at smaller screens
        this is due to ajaxing in the header, which
        removes the bound js that normally alters the
        dom to append the news into the mobile menu.
*/
@media all and (max-width: 929px) {
    .responsive-template #news-header {
        height: 0;
        position: absolute;
        top: -500px;
    }
}

/* Fix links in sidebar */
#main a {
  color:#006eaf;
}

/* hide elements */
.hide-element
{
	display: none;
	visibility: hidden;
	width: 0;
}

/* Footer */
footer.footer .footer-social a {
	text-decoration: none;
}

/* Make font paths absolute:  */


@font-face {
  font-family: 'firasans';
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Light.eot");
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Light.eot?#iefix") format("embedded-opentype"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Light.woff") format("woff"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'firasans';
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-LightItalic.eot");
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-LightItalic.eot?#iefix") format("embedded-opentype"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-LightItalic.woff") format("woff"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'firasans';
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Regular.eot");
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Regular.eot?#iefix") format("embedded-opentype"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Regular.woff") format("woff"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'firasans';
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Italic.eot");
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Italic.eot?#iefix") format("embedded-opentype"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Italic.woff") format("woff"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'firasans';
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Bold.eot");
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Bold.eot?#iefix") format("embedded-opentype"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Bold.woff") format("woff"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'firasans';
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-BoldItalic.eot");
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-BoldItalic.woff") format("woff"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'firasans';
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Medium.eot");
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Medium.eot?#iefix") format("embedded-opentype"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Medium.woff") format("woff"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'firasans';
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-MediumItalic.eot");
  src: url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-MediumItalic.eot?#iefix") format("embedded-opentype"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-MediumItalic.woff") format("woff"), url("//www.imperial.ac.uk/T4Assets/fonts/fira-sans/FiraSans-MediumItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'imperial-icons';
  src: url("//www.imperial.ac.uk/T4Assets/fonts/icons/fonts/imperial-icons.eot?vrmf2d");
  src: url("//www.imperial.ac.uk/T4Assets/fonts/icons/fonts/imperial-icons.eot?#iefixvrmf2d") format("embedded-opentype"), url("//www.imperial.ac.uk/T4Assets/fonts/icons/fonts/imperial-icons.woff?vrmf2d") format("woff"), url("//www.imperial.ac.uk/T4Assets/fonts/icons/fonts/imperial-icons.ttf?vrmf2d") format("truetype"), url("//www.imperial.ac.uk/T4Assets/fonts/icons/fonts/imperial-icons.svg?vrmf2d#imperial-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}